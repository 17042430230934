import { useState, useEffect} from 'react';
import { Box, Button, Typography, Table, FormControl, FormLabel, Select, Option } from '@mui/joy';
import repo from 'data/repo';

const GradeResults = () => {

    const [assignments, setAssignments] = useState([]);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [assignmentSubmissions, setAssignmentSubmissions] = useState([]);

    useEffect(() => {
        repo.getAssignments().then((r) => {
            if (r.isSuccess) {
                setAssignments(r.data);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedAssignmentId > 0) {
            loadAssignmentSubmissions();
        }
    }, [selectedAssignmentId]);

    const loadAssignmentSubmissions = () => {
        repo.getAssignmentSubmissions(selectedAssignmentId).then((r) => {
            if (r.isSuccess) {                
                setAssignmentSubmissions(r.data);
            }
        });
    };

    return <>
        <Box
            sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2" component="h1">
                Scores
            </Typography>

            <FormControl>
                <FormLabel>

                </FormLabel>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <Select placeholder='Choose Assignment...' onChange={(e, newValue) => { setSelectedAssignmentId(newValue); }} value={selectedAssignmentId} sx={{ flex: '2' }}>
                        {assignments.map((a) => {
                            return <Option key={a.id} value={a.id}>{a.name}</Option>
                        })}
                    </Select>
                </Box>
            </FormControl>

            {selectedAssignmentId && <Table>
                <thead>
                    <tr>
                        <th style={{ width: '40%' }}>Student</th>
                        <th>Scored Date</th>
                        <th style={{ textAlign: 'right' }} align='right'>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {assignmentSubmissions.map((s)=>{
                        return <tr key={s.id}>
                            <td>{s.name}</td>
                            {s.hasGradingError && <><td colSpan={2}>
                                <i>The AI had trouble grading this student's response.</i>
                            </td></>}

                            {!s.hasGradingError && <>
                                <td>{s.isScoreComplete ? new Date(s.scoreDate).toLocaleDateString() : 'Not scored'}</td>
                                <td align='right'>{s.isScoreComplete ? s.totalScore : ''}</td>
                            </>}
                        </tr>
                    })}
                </tbody>                
            </Table>}
        </Box>
    </>;
};

export default GradeResults;