import { useContext, createContext, useState, useEffect } from 'react';
import repo from 'data/repo';

export const OrgContext = createContext(null);

let triggerReference = null;

export const OrgProvider = ({ children }) => {

    const [org, setOrg] = useState({});
    const [trigger, setTrigger] = useState();

    // So that we can trigger the a refresh from outside this function
    triggerReference = setTrigger;

    useEffect(()=>{        
        repo.getOrgInfo().then((r)=>{
            console.log("Org info retrieved", r);
            setOrg(r.data);
        });
    }, [trigger]);

    return <OrgContext.Provider value={org}>
        {children}
    </OrgContext.Provider>
};

export const updateCredits = () => {
    // Set a random trigger to get a refresh effect to happen
    triggerReference(new Date());
};

export const useOrgContext = () => {
    return useContext(OrgContext);
};

