import { useState, useEffect } from 'react';
import { Typography, Button, Box, Modal, ModalClose, ModalDialog, DialogTitle, DialogContent, Stack, FormControl, FormLabel, Input } from '@mui/joy';
import repo from 'data/repo';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';

const Users = () => {

    const [users, setUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(()=>{
        loadUsers();
    }, []);

    const loadUsers = () => {
        repo.getOrgUsers().then((r) => {
            setUsers(r.data);
        });
    };

    const inviteUser = () => {
        repo.inviteOrgUser(firstName, lastName, email).then((r)=>{
            setIsOpen(false);
            if (r.isSuccess) {
                loadUsers();
            }
        });
    };

    return <>
        
        <Box
            sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2" component="h1">
                Users
            </Typography>
            <Button color="primary" size="sm" onClick={() => { setIsOpen(true); }} >
                Invite User
            </Button>            
        </Box>

        <Typography level='body-md'>Users will be able to share your organization's resources, such as grading rubrics.</Typography>

        <Box sx={{ py: 2}}>
        
            <Typography level='title-lg'>Current Users</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {users.map((u)=>{
                    return <ListItem key={u.id}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${u.firstName} ${u.lastName}`} secondary={u.email} />
                    </ListItem>;
                })}
                
            </List>

        </Box>

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Invite User</DialogTitle>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>First Name</FormLabel>
                        <Input autoFocus required value={firstName} onChange={(e)=>{ setFirstName(e.target.value); }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Input required value={lastName} onChange={(e) => { setLastName(e.target.value); }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input required value={email} onChange={(e) => { setEmail(e.target.value); }} />
                    </FormControl>
                    <Button type="submit" onClick={inviteUser}>Invite</Button>
                </Stack>                
            </ModalDialog>
        </Modal>
    </>;
};

export default Users;