import React from 'react';
import ReactDOM from 'react-dom/client';

import * as ReactDom from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '@fontsource/inter';

import Root from './routes/root';
import ErrorPage from './error-page';
import Login from './routes/login';
import Signup from 'routes/signup';
import SignupInvite from 'routes/signupInvite';
import Dashboard from 'routes/dashboard';
import Rubrics from 'routes/rubrics';
import GradeResults from 'routes/gradeResults';
import Assignments from 'routes/assignments';
import Users from 'routes/userManagement';
import '@fontsource/inter';

import { mlsAuthProvider, useAuth, AuthContext, RequireAuth, AuthProvider } from 'provider/authProvider';
import { OrgProvider } from 'provider/orgProvider';

const router = createBrowserRouter([
    {
        path: "/",
        element: <RequireAuth><Root /></RequireAuth>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/rubrics',
                element: <Rubrics />
            },
            {
                path: '/assignments',
                element: <Assignments />
            },
            {
                path: '/grades',
                element: <GradeResults />
            },
            {
                path: '/userManagement',
                element: <Users />
            }
        ],
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/signup',
        element: <Signup />
    },
    {
        path: '/setupaccount/:inviteHash',
        element: <SignupInvite />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <OrgProvider>
                <RouterProvider router={router} />
            </OrgProvider>
        </AuthProvider>
    </React.StrictMode>
);
