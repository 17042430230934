import { useState, useEffect } from 'react';
import { FormControl, FormLabel, Typography, Select, Option, Stack, Button, Box, Input, Textarea, Card, CardOverflow, CardContent, Sheet, Alert, Link } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import repo from 'data/repo';
import _, { assign } from 'underscore';
import { useOrgContext, updateCredits } from 'provider/orgProvider';

const Assignments = () => {
    const [assignments, setAssignments] = useState([]);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [assignmentSubmissions, setAssignmentSubmissions] = useState([]);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);

    useEffect(()=>{
        repo.getAssignments().then((r)=>{            
            if (r.isSuccess) {
                setAssignments(r.data);
            }
        });
    }, []);

    useEffect(()=>{
        if (selectedAssignmentId > 0) {
            loadAssignmentSubmissions();
        }
    }, [selectedAssignmentId]);

    const loadAssignmentSubmissions = (newSelectedId) => {
        repo.getAssignmentSubmissions(selectedAssignmentId).then((r) => {
            if (r.isSuccess) {
                if (newSelectedId) {
                    // Select this new id
                    setSelectedSubmissionId(newSelectedId);
                }
                setAssignmentSubmissions(r.data);
            }
        });
    };

    const reloadSubmissions = (newSelectedId) => {
        loadAssignmentSubmissions(newSelectedId);
    };

    let selectedAssignment = _.findWhere(assignments, { id: selectedAssignmentId });
    let selectedSubmission = _.findWhere(assignmentSubmissions, { id: selectedSubmissionId });

    // To accomodate new, make a fake selectedSubmission when selectedId = -1
    if (selectedSubmissionId === -1) {
        selectedSubmission = {
            name: '',
            assignmentContent: '',
            id: -1
        };
    }

    return <>
        <Typography level='h1'>Assignments</Typography>
        <Typography level='body-md'></Typography>
        
        <Stack direction='column' spacing={1}>
            <FormControl>
                <FormLabel>
                    
                </FormLabel>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <Select placeholder='Choose Assignment...' onChange={(e, newValue) => { setSelectedAssignmentId(newValue); }} value={selectedAssignmentId} sx={{ flex: '2' }}>
                        {assignments.map((a) => {
                            return <Option key={a.id} value={a.id}>{a.name}</Option>
                        })}
                    </Select>
                    <Button dcolor="neutral" variant="outlined" startDecorator={<AddIcon />} sx={{ flex: '1' }}>
                        Add Assignment
                    </Button>
                </Box>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Select disabled={!selectedAssignmentId} placeholder='Choose Student Assignment...' onChange={(e, newValue) => { setSelectedSubmissionId(newValue); }} value={selectedSubmissionId} sx={{ flex: '2' }}>
                    {assignmentSubmissions.map((a) => {
                        return <Option key={a.id} value={a.id}>{a.name}</Option>
                    })}
                </Select>
                <Button onClick={() => setSelectedSubmissionId(-1)} variant="outlined" startDecorator={<AddIcon />} sx={{ flex: '1' }}>
                    Add Submission
                </Button>
            </Box>

            <Submission key={selectedSubmissionId} assignment={selectedAssignment} submission={selectedSubmission} onReload={reloadSubmissions} />
        </Stack>
    </>;
};

const Submission = ({ assignment, submission, onReload }) => {

    const [name, setName] = useState('');
    const [response, setResponse] = useState('');
    const [isLoadingScore, setIsLoadingScore] = useState(false);
    const [gradeResponses, setGradeResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if (submission) {
            setName(submission.name);
            setResponse(submission.assignmentContent);
        }

        // Load scores if there is a submission id
        if (submission && submission.id > 0) {
            repo.getGradeResponse(submission.id).then((r)=>{
                setGradeResponses(r.data);
            });
        }

        setIsLoadingScore(false);
    }, [submission]);

    const saveChanges = () => {
        setIsLoading(true);
        repo.saveAssignmentSubmission(assignment.id, submission.id, name, response).then((r)=>{
            setIsLoading(false);
            if (submission.id <= 0) {                
                onReload(r.data.id);
            }
        });
    };

    const getGrades = () => {
        setIsLoadingScore(true);
        
        // Update credits before and after, just to make sure we are current
        updateCredits();

        try {
            repo.getSubmissionGrade(submission.id).then(()=>{
                updateCredits();
                
                // trigger reload of data
                onReload();
            }).catch(()=>{
                console.log("Error during execution.");  
                
                // We expect the timeout to go here since grading takes a while. Timeout gives the grading enough time to set the start date so we'll reload it now
                onReload();  
            });
        } catch {
            console.log("Error during execution.");
        }
    };

    if (!assignment || !submission) {
        return null;
    }

    let gradeCategories = _.groupBy(gradeResponses, (g)=>{ return g.categoryName; });    
    let totalScore = _.reduce(gradeResponses, (num, g)=>{ return num + g.score; }, 0);  
        
    return <>
        <h2>Assignment Submission Detail</h2>
        <Stack spacing={2}>
            <Input placeholder='Student name' value={name} onChange={(e) => { setName(e.target.value); }} />
            <Textarea minRows={10} placeholder='Student Response' value={response} onChange={(e) => { setResponse(e.target.value); }} />
            <Button disabled={isLoading} onClick={saveChanges}>Save</Button>

            {gradeResponses.length > 0 && <Box>
                <Typography level='h4'>Grade Results</Typography>

                <Card orientation="horizontal" sx={{
                        flexWrap: 'wrap',
                        [`& > *`]: {
                            '--stack-point': '500px',
                            minWidth:
                                'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
                        }
                    }}
                >

                    <Stack direction='column'>                        
                        <div style={{ textAlign: 'center' }}><Typography level='title-lg'>Total Points</Typography></div>
                        <div style={{ textAlign: 'center' }}><Typography level='h1' fontWeight='lg'>{totalScore}</Typography></div>
                    </Stack>
                    
                    <CardContent>
                        <Sheet
                            sx={{
                                bgcolor: 'background.level1',
                                borderRadius: 'sm',
                                p: 1.5,
                                my: 0.5,
                                display: 'flex',
                                gap: 0,
                                '& > div': { flex: 1 },
                            }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography level="body-xs" fontWeight="lg">
                                    Organization
                                </Typography>
                                <Typography fontWeight="lg">{_.reduce(gradeCategories.Organization, (num, g) => { return g.score + num; }, 0)}</Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography level="body-xs" fontWeight="lg">
                                    Elaboration
                                </Typography>
                                <Typography fontWeight="lg">{_.reduce(gradeCategories.Elaboration, (num, g) => { return g.score + num; }, 0)}</Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography level="body-xs" fontWeight="lg">
                                    Conventions
                                </Typography>
                                <Typography fontWeight="lg">{_.reduce(gradeCategories.Conventions, (num, g) => { return g.score + num; }, 0)}</Typography>
                            </div>
                        </Sheet>
                        <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>                            
                        </Box>
                    </CardContent>
                </Card>

                <Typography level='h4' style={{ marginTop: '12px' }}>Scoring Detail</Typography>
                {gradeResponses.map((g, idx) => {
                    return <Card key={idx} orientation="horizontal" size="sm" sx={{ bgcolor: 'background.surface', borderRadius: 4, mb: 1 }} >
                        <CardOverflow sx={{ bgcolor: 'background.level1' }}>
                            <Box style={{ width: '40px', textAlign: 'center', paddingLeft: '5px', paddingRight: '5px' }}>
                                <Typography level='h1'>{g.score}</Typography>
                            </Box>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="title-sm">{g.categoryName} / {g.criteriaName}</Typography>
                            <Typography level="body-sm">{g.justification}</Typography>
                        </CardContent>
                    </Card>;
                })}
            </Box>}

            {!submission.gradingStarted && <Button disabled={submission.id === -1 || isLoadingScore} onClick={getGrades}>Start AI Grading</Button>}
            {(isLoadingScore || (submission.gradingStarted && !submission.gradingFinished && gradeResponses.length === 0) ) && <><Alert color="primary" variant="soft">Our AI assistant has started grading this student's submission. Please check back in a few minutes to see results. <Link>Refresh</Link></Alert></>}
        </Stack>
    </>;
};

export default Assignments;