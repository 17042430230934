import React, { useState } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import Box from '@mui/joy/Box';
import { Link } from 'react-router-dom'
import repo from 'data/repo';

const Signup = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onSignupClick = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        let response = await repo.registerAccount(firstName, lastName, email, password);
        if (response.isSuccess) {
            setShowSuccess(true);
            setIsLoading(true);
        } else {
            // Error happened
            setErrorMessage('Unable to create your account.');
            setIsLoading(false);
        }
    };

    return (
        <CssVarsProvider>
            <main>

                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & bottom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                            <b>Welcome!</b>
                        </Typography>
                        <Typography level="body-sm">Create your free account.</Typography>
                    </div>                    
                    <FormControl>
                        <FormLabel>First name</FormLabel>
                        <Input name="firstName" placeholder="Given name" value={firstName} onChange={(e) => { setFirstName(e.target.value); }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Last name</FormLabel>
                        <Input name="lastName" placeholder="Last name" value={lastName} onChange={(e) => { setLastName(e.target.value); }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input name="email" type="email" placeholder="johndoe@email.com" value={email} onChange={(e) => { setEmail(e.target.value); }} />
                    </FormControl>                    
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input name="password" type="password" placeholder="password" value={password} onChange={(e) => { setPassword(e.target.value); }} />
                    </FormControl>

                    {errorMessage && <ErrorMessage message={errorMessage} />}

                    {showSuccess ? 
                        <SuccessMessage /> : 
                        <Button disabled={isLoading} onClick={onSignupClick} sx={{ mt: 1 /* margin top */ }}>Sign up</Button>
                    }
                </Sheet>
            </main>
        </CssVarsProvider>
    );
}

const ErrorMessage = ({ message }) => {
    return <Alert color='danger'>{message}</Alert>;
}

const SuccessMessage = () => {
    return <div>
        <Alert variant='soft' color='success' >
            <Box sx={{ flex: 1 }}>
                <Typography level="title-md">Account Creation Successful</Typography>
                <Typography level="body-md">
                    Your account has been created. Please proceed to the login page to continue.
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Link to='/login'>
                        Login
                    </Link>
                </Box>
            </Box>    
        </Alert>    
    </div>
};

export default Signup;