import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import repo from 'data/repo';
import cookie from 'cookie';

/**
 * This represents some generic auth provider API, like Firebase.
 */
const mlsAuthProvider = {
    isAuthenticated: cookie.parse(document.cookie).token !== undefined && cookie.parse(document.cookie).token !== "",
    isAdmin: cookie.parse(document.cookie).isAdmin !== undefined && cookie.parse(document.cookie).isAdmin === 'true',
    signin(userName, password, callback) {
        repo.authenticate(userName, password).then((r) => {

            let cookies = cookie.parse(document.cookie); 
            let accessToken = cookies.token;
 
            //console.log("Auth", accessToken, cookies);
            if (r.token.success) {
                // Login good
                console.log("isAdmin", r.token);

                mlsAuthProvider.isAuthenticated = true;
                document.cookie = `token=${r.token.token}; expires=Thu, 18 Dec 2199 12:00:00 UTC`;
                document.cookie = `isAdmin=${r.token.isAdmin}; expires=Thu, 18 Dec 2199 12:00:00 UTC`;
                mlsAuthProvider.isAdmin = r.token.isAdmin;

                console.log("Cookie", cookie.parse(document.cookie).isAdmin !== undefined && cookie.parse(document.cookie).isAdmin === true);
                callback(true);
            } else {
                // Failed to login
                mlsAuthProvider.isAuthenticated = false;
                mlsAuthProvider.isAdmin = false;
                document.cookie = `token=`;
                document.cookie = `isAdmin=`;
                callback(false);
            }
        });

    },
    signout(callback) {
        console.log("Logging out...");
        mlsAuthProvider.isAuthenticated = false;
        document.cookie = `token=`;
        document.cookie = `isAdmin=`;
        setTimeout(callback, 100);
    },
};

function useAuth() {
    return React.useContext(AuthContext);
}

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
    let [user, setUser] = React.useState(null);
    let [password, setPassword] = React.useState(null);
    let [isAdmin, setIsAdmin] = React.useState(null);

    let signin = (newUser, password, callback) => {
        return mlsAuthProvider.signin(newUser, password, (r) => {
            setUser(newUser);
            setPassword(password)
            callback(r);
        });
    };

    let signout = (callback) => {
        return mlsAuthProvider.signout(() => {
            setUser(null);
            callback();
        });
    };

    let value = { user, isAuthenticated: mlsAuthProvider.isAuthenticated, signin, signout, isAdmin: mlsAuthProvider.isAdmin };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function RequireAuth({ children }) {
    let auth = useAuth();
    let location = useLocation();

    console.log("RequireAuth", auth);

    if (!auth.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export { mlsAuthProvider, useAuth, AuthContext, RequireAuth, AuthProvider };