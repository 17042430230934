/* eslint-disable no-undef */
var config = {
    apiUrl: "https://api.aiscorehelper.com",    
};

if (process.env.NODE_ENV !== 'production') {
    config.apiUrl = "http://localhost:5126";
}

config.googleClientId = '665601849148-0ou7i4pnclq4aq93qidgn8jnsqjlq9v2.apps.googleusercontent.com';
config.googleApiKey = 'AIzaSyAudWb4WS2IukGIk1XLYCBhPao3a_HX-D8';

export default config;