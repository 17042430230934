import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer, Button, Box, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ListDivider } from '@mui/joy';
import { Link } from 'react-router-dom';
import RubricsIcon from '@mui/icons-material/GridOn';
import GradeResultsIcon from '@mui/icons-material/Grading';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UserIcon from '@mui/icons-material/People';
import { useOrgContext } from 'provider/orgProvider';

import '../root.css';

const MenuOptions = () => {

    const org = useOrgContext();

    return <Box>
        <List>
            <ListItem>
                <ListItemButton>
                    <ListItemDecorator><RubricsIcon /></ListItemDecorator>
                    <ListItemContent><Link to='/rubrics' style={{ textDecoration: 'none' }}>Rubrics</Link></ListItemContent>                    
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemDecorator><AssignmentIcon /></ListItemDecorator>
                    <ListItemContent><Link to='/assignments' style={{ textDecoration: 'none' }}>Assignments</Link></ListItemContent>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemDecorator><GradeResultsIcon /></ListItemDecorator>
                    <ListItemContent><Link to='/grades' style={{ textDecoration: 'none' }}>Scores</Link></ListItemContent>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <ListItemDecorator><UserIcon /></ListItemDecorator>
                    <ListItemContent><Link to='/userManagement' style={{ textDecoration: 'none' }}>User Management</Link></ListItemContent>
                </ListItemButton>
            </ListItem>
            <ListDivider />
            <ListItem>
                <ListItemContent title='Credits available for AI assist'>{org / 1000} credits</ListItemContent>
            </ListItem>
        </List>
    </Box>
};

export default function Root() {

    return (
        <Grid container className='root'>
            <MobileMenu />            
            <Grid sm={3} id="sidebar" sx={{ display: { xs: 'none', sm: 'block' }}}>
                <h1>AI Teacher's Assistant</h1>
                <nav>
                    <MenuOptions />
                </nav>                
            </Grid>
            <Grid xs={12} sm={9} id="detail">                
                <Outlet />                
            </Grid>
        </Grid>
    );
}

const MobileMenu = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleDrawer = (inOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsMenuOpen(inOpen);
    };

    return <>
        <Button onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', sm: 'none' }}}>Menu</Button>
        <Drawer open={isMenuOpen} onClose={toggleDrawer(false)}>


        </Drawer>
    </>;
};