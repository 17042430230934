import axios from 'axios';
import cookie from 'cookie';
import settings from 'appSettings';

class Repo {

    //baseUrl = 'http://localhost:5126';
    baseUrl = settings.apiUrl;

    getClient = (timeout) => {

        let token = cookie.parse(document.cookie).token;

        return axios.create({
            baseURL: this.baseUrl,
            timeout: timeout ?? 1300000,
            validateStatus: () => { return true; },
            headers: { 
                'Authorization': `bearer ${token}`
            }
        });
    }

    registerAccount = async (firstName, lastName, email, password) => {
        let client = this.getClient();
        let response = await client.post('/v1/register', { firstName, lastName, email, password });
        return response.data;
    }

    authenticate = async (email, password) => {
        let client = this.getClient();
        let response = await client.post('/v1/authenticate', { email, password });
        return response.data;
    }

    getAssignments = async() => {
        let client = this.getClient();
        let response = await client.get('/v1/assignments');
        return response.data;
    }

    getAssignmentSubmissions = async(assignmentId) => {
        let client = this.getClient();
        let response = await client.get(`v1/assignmentSubmission/${assignmentId}`);
        return response.data;
    }

    saveAssignmentSubmission = async(assignmentId, submissionId, name, assignmentContent) => {
        let client = this.getClient();
        let response = await client.post('/v1/assignmentSubmission', { assignmentId, submissionId, name, assignmentContent });
        return response.data;
    }

    getSubmissionGrade = async(submissionId) => {
        let client = this.getClient(5000);
        let response = await client.get(`/v1/grade/${submissionId}`);
        return response.data;
    };

    getSubmissionGradeStreamed = async (submissionId) => {
        let token = cookie.parse(document.cookie).token;

        const response = await fetch(`${this.baseUrl}/v1/grade/${submissionId}`, {
            method: 'GET',
            signal: AbortSignal.timeout(100000),
            headers: {
                'Authorization': `bearer ${token}`
            }
        });

        const reader = response.body.getReader();

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                console.log("Stream complete");
                break;
            }

            console.log("AI Grader:", new TextDecoder().decode(value));
        }

    };

    getGradeResponse = async(submissionId) => {
        let client = this.getClient();
        let response = await client.get(`/v1/gradeResponse/${submissionId}`);
        return response.data;
    }

    getOrgUsers = async() => { 
        let client = this.getClient();
        let response = await client.get(`/v1/orgUsers`);
        return response.data;
    }

    inviteOrgUser = async(firstName, lastName, email) => {
        let client = this.getClient();
        let response = await client.post('/v1/orgUsers', { firstName, lastName, email });
        return response.data;
    }

    completeInvite = async(inviteHash, password) => {
        let client = this.getClient();
        let response = await client.post('/v1/completeInvite', { inviteHash, password });
        return response.data;
    }

    getOrgInfo = async() => {
        let client = this.getClient();
        let response = await client.get('/v1/orgCredits');
        return response.data;
    }
}

export default new Repo();
