import React, { useEffect, useState } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import repo from 'data/repo';
import { useAuth } from 'provider/authProvider';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/joy';

export default function LoginFinal() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState();
    
    let auth = useAuth();
    let navigate = useNavigate();

    const doLogin = async () => {
        auth.signin(email, password, (success) => {
            // On completion
            if (success) {
                navigate('/');
            } else {
                setMessage('Unable to login');
            }
        });
    };

    return (
        <CssVarsProvider>
            <main>
                
                <Sheet
                    sx={{
                        width: 300,
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & bottom
                        py: 3, // padding top & bottom
                        px: 2, // padding left & right
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <div>
                        <Typography level="h4" component="h1">
                            <b>Welcome!</b>
                        </Typography>
                        <Typography level="body-sm">Sign in to continue.</Typography>
                    </div>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input name="email" type="email" value={email} placeholder="johndoe@email.com" onChange={(e)=>{ setEmail(e.target.value); }} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Password</FormLabel>
                        <Input name="password" type="password" value={password} placeholder="password" onChange={(e)=>{ setPassword(e.target.value); }} />
                    </FormControl>

                    {message && <Alert color='danger'>{message}</Alert>}

                    <Button onClick={doLogin} sx={{ mt: 1 /* margin top */ }}>Log in</Button>
                    <Typography
                        endDecorator={<Link href="/signup">Sign up</Link>}
                        fontSize="sm"
                        sx={{ alignSelf: 'center' }}
                    >
                        Don&apos;t have an account?
                    </Typography>
                </Sheet>
            </main>
        </CssVarsProvider>
    );
}